<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {getLists, updateData, deleteData, getData, createData, setStatus} from "../../../helpers/api/api";
import { required } from 'vuelidate/lib/validators';
import { GetSpuList } from "@/utils/api";
const moment = require('moment')
const upload = require("../../../helpers/image-upload");

/**
 * Products Component
 */
export default {
  page: {
    title: "Product",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component
  },
  data() {
    return {
        title: this.$t('content.products.text'),
        items: [
          {
            text: this.$t('content.products.text'),
          },
          {
            text: this.$t('content.products.lists'),
            active: true
          }
        ],
        sliderPrice: 800,
        createCategoryModel: false,
        editor: ClassicEditor,
        editorData: '',
        editorConfig: {
            placeholder: this.$t('common.editorcontent'),
            extraPlugins: [upload.MyCustomUploadAdapterPlugin]
        },
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        filter: null,
        filterOn: [],
        sortBy: "orderid",
        sortDesc: false,
        productData: [],
        editProductModel: false,
        editImageModal: false,
        brandData: [],
        product_id: 0,
        productName: '',
        inventory: 0,
        price: '',
        salePrice: '',
        brandId: "",
        categoryData: [],
        categoryId: '',
        categoryName: '',
        gallery: [],
        gallerys: [],
        gallery_product_id: '',
        firstCategory: [],
        secondCategory: [],
        thirdCategory:[],
        firstCategoryID: '',
        secondCategoryID: '',
        thirdCategoryID: '',
        vendorId: '',
        description: '',
        volume: '',
        weight: '',
        image:'',
        uploadImageModal: false
    };
  },
  validations: {
    productName: {required},
    categoryId: {required},
    inventory: {required},
    price: {required},
    salePrice: {required},
    brandId: {required},
    // volume: {required},
    // weight: {required}
  },
  created() {
      this.productLists(this.currentPage)
      this.categoryLists();
      // this.initCKEditor();
  },
  methods: {
    
      brandLists() {
        getLists('GET', 'brand').then(res => {
            this.brandData = res.data;
        });
      },

      categoryLists() {
        getLists('GET', 'category').then(res => {
            this.categoryData = res.data;
            this.getFirst(res.data);
        });
      },

      getFirst(data) {
          data.forEach(item => {
              if (item.parent == null) {
                  this.firstCategory.push(item);
              }
          });
      },

      getSecond(id) {
        this.secondCategory = [];
          this.categoryData.forEach(item => {
              if (item.parent == id) {
                  this.secondCategory.push(item)
              }
          });
      },

      getThird(id) {
          this.thirdCategory = [];
          this.categoryData.forEach(item => {
              if (item.parent == id) {
                  this.thirdCategory.push(item)
                  this.categoryId = id;
              }
          });
      },

      getCategory(id) {
          this.categoryId = id;
      },

      getShowCategory(id) {
         this.categoryData.forEach(item => {
            if (item._id == id) {
                this.getThird(item.parent);
                this.thirdCategoryID = id;

                this.categoryData.forEach(second => {
                    if (second._id == item.parent) {
                        this.getSecond(second.parent);
                        this.secondCategoryID = second._id;

                        this.firstCategoryID = second.parent;
                    }
                });
            }
         });
      },

      productLists(page) {
        GetSpuList({page}).then(res => {
              this.productData = [];
              this.totalRows = res.data.count;  
              res.data.rows.forEach(item => {
                  item.status = item.status == 0 ? true : false;
                  item.createdAt = moment(item.createdAt).format('MMMM,DD YYYY HH:mm:ss');
                  this.productData.push(item);
              })
          });
      },

      submitData() {
          this.$v.$touch()
          if (this.$v.$invalid) {
                
              this.$swal({
                  text: this.$t('common.submitError'),
                  confirmButtonText: this.$t('common.ok')
              });

          } else {
            updateData('PUT', 'product', this.product_id, {
                    productName: this.productName,
                    categoryId: this.categoryId,
                    inventory: this.inventory,
                    price: this.price,
                    salePrice: this.salePrice,
                    brandId: this.brandId,
                    vendorId: this.vendorId,
                    description: this.editorData,
                    // volume: this.volume,
                    // weight: this.weight
                }).then(res => {
                    this.$swal({
                        text: res.message,
                        confirmButtonText: this.$t('common.ok')
                    });

                    this.productLists(this.currentPage);
                });
              
          }
      },

      editProduct(id) {
        this.editProductModel = true;
        this.brandLists()

        for (var i=0; i<this.productData.length; i++) {
            if (this.productData[i]['_id'] == id) {
                this.product_id = id;
                this.productName = this.productData[i]['productName'];
                this.price = this.productData[i]['price'];
                this.salePrice = this.productData[i]['salePrice'];
                this.inventory = this.productData[i]['inventory']['quantity'];
                this.categoryId = this.productData[i]['categoryId'];
                this.categoryName = this.productData[i]['categoryName'];   
                this.brandId = this.productData[i]['brandId'];
                this.vendorId = this.productData[i]['vendorId'];
                this.editorData = this.productData[i]['description'];

                this.getShowCategory(this.categoryId);
            }
        }

      },

      getGallery(product_id) {
           getData('GET', 'product', product_id).then(res => {
              this.gallerys = res.data.gallery
              this.gallery_product_id = product_id;
          });
      },

      fileChange() {
          var that = this;
          var files = document.getElementById("image").files[0];
          var reader = new FileReader();
          reader.onloadend = function () {
              that.image = reader.result;
          };
          reader.readAsDataURL(files);
      },

      uploadImage() {
          this.uploadImageModal = true;
      },

      uploadImageSave() {

          if (this.image == '') {
              this.$swal({
                  text: this.$t('common.imagerequired')
              });
          } else {
              this.image = this.image.replace(/^data:image\/\w+;base64,/, "");

              createData('POST', 'product/gallery', {
                  isPrimary: false,
                  productId: this.gallery_product_id,
                  varientId: '',
                  sortingOrder: 10,
                  img: this.image
              }).then(res => {
                  this.$swal({
                      text: res.message
                  });
                  this.uploadImageModal = false;
                  this.getGallery(this.gallery_product_id);
              });
          }
          
      },

      editImage(id) {
          this.editImageModal = true;
        
          this.getGallery(id);
      },

      deleteImage(id, gallery_product_id) {
          this.$swal({
              text: this.$t('common.confirmdelete'),
              confirmButtonText: this.$t('common.ok'),
              cancelButtonText: this.$t('common.cancel'),
              showCancelButton: true
          }).then((res) => {
              if (res.isConfirmed == true) {

                  deleteData('DELETE', 'product/gallery', id).then(res => {
                      this.$swal({
                          text: res.message,
                          confirmButtonText: this.$t('common.ok')
                      });

                      this.getGallery(gallery_product_id);
                  });

              }
          }); 
      },

      setProductStatus(id, status) {
          var set_status = status == true ? 0 : 1;
          setStatus('PUT', 'product', id, set_status).then();
      },

      onPageChange() {
          this.productLists(this.currentPage);
      },
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <div class="card">
          
          <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">{{$t('content.products.allproduct')}}</a>
                </template>
                
                
                <div class="table-responsive">

                  <table
                    class="table table-centered datatable dt-responsive nowrap"
                    style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                  >
                  <thead class="thead-light">
                    <tr>
                      <th>{{$t('common.id')}}</th>
                      <th>{{$t('common.image')}}</th>
                      <th>{{$t('content.products.title')}}</th>
                      <th>{{$t('content.products.price')}}</th>
                      <th>{{$t('content.products.saleprice')}}</th>
                      <th>{{$t('common.category')}}</th>
                      <th>{{$t('common.vendorname')}}</th>
                      <th>{{$t('common.status')}}</th>
                      <th>{{$t('common.createtime')}}</th>
                      <th>{{$t('common.action')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in productData" :key="index">
                      <td>{{item.id}}</td>
                      <td><img :src="item.img" width="50" /></td>
                      <td>{{item.spuName}}</td>
                      <td>{{item.maxPrice}}</td>
                      <td>{{item.minPrice}}</td>
                      <td>{{item.categoryName}}</td>
                      <td>{{item.vendorName}}</td>
                      <td>

                         <b-form-checkbox v-model="item.status" @change="setProductStatus(item._id, item.status)" switch class="mb-1"></b-form-checkbox>

                      </td>
                      <td>{{item.createdAt}}</td>
                      <td>
                        <!-- <a
                          href="javascript:void(0);"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          :title="$t('common.view')"
                          @click="viewProduct(item._id)"
                        >
                          <i class="mdi mdi-eye font-size-18"></i>
                        </a> -->

                        <a
                          href="javascript:void(0);"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          :title="$t('common.image')"
                          @click="editImage(item._id)"
                        >
                          <i class=" ri-image-add-line  font-size-18"></i>
                        </a>

                        <a
                          href="javascript:void(0);"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          :title="$t('common.edit')"
                          @click="editProduct(item._id)"
                        >
                          <i class="mdi mdi-pen font-size-18"></i>
                        </a>
<!-- 
                        <a
                          href="javascript:void(0);"
                          class="mr-3 text-primary"
                          v-b-tooltip.hover
                          :title="$t('common.delete')"
                          @click="deleteProduct(item._id)"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" @input="onPageChange"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>

                <br /><br />
              </b-tab>
            </b-tabs>

        </div>
      </div>
    </div>
    <!-- end row -->

    <b-modal v-model="uploadImageModal" :title="$t('common.image')" centered>
      <form @submit.prevent="submit">
            <div class="form-group row-box">
                <input type="file" id="image" accept="image/*" class="form-control" :placeholder="$t('content.gallery.url')" @change="fileChange" />
            </div>
        
        </form>
        <template v-slot:modal-footer>
            <b-button variant="secondary" @click="uploadImageModal = false">{{$t('common.close')}}</b-button>
            <b-button variant="primary" @click="uploadImageSave">
            {{$t('common.submit')}}
            <i class="fab fa-telegram-plane ml-1"></i>
            </b-button>
        </template>
    </b-modal>

    <b-modal v-model="editImageModal" size="xl" :title="$t('content.products.editImage')" centered>
      <form @submit.prevent="submit">

          <div class="tab-pane" id="basic-info" style="min-height: 200px; overflow: hidden">
            <div class="form-group">
                <button class="form-control" type="button" @click="uploadImage">{{$t('common.uploadFileText')}}</button>
            </div>
            <div class="form-group">
                <div class="tab-pane" id="product-img">
                    <div :id="item._id" class="product_image" v-for="(item, index) in gallerys" :key="index">
                          <div class="image-delete" @click="deleteImage(item._id, gallery_product_id)"><i class="mdi mdi-trash-can font-size-18"></i></div>
                          <img :src="item.img" align="middle" />
                    </div>
                </div>
            </div>
          </div>
      </form>
    </b-modal>

    <b-modal v-model="editProductModel" size="xl" :title="$t('content.products.edit')" centered>
       <form @submit.prevent="submit">
        
          <div class="tab-pane" id="basic-info">
                  <form>
                    <div class="form-group">
                      <label for="productname">{{$t('content.products.title')}}</label>
                      <input id="productname" v-model.trim="$v.productName.$model" type="text" class="form-control" />
                      <span class="error" v-if="!$v.productName.required">* {{$t('common.namerequired')}}</span>
                    </div>
                    <div class="row row-box">
                      
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="price">{{$t('content.products.price')}}</label>
                          <input id="price" v-model.trim="$v.price.$model" type="number" class="form-control" />
                          <span class="error" v-if="!$v.price.required">* {{$t('common.pricerequired')}}</span>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="manufacturername">{{$t('content.products.saleprice')}}</label>
                          <input id="sale_price" v-model.trim="$v.salePrice.$model" type="number" class="form-control" />
                          <span class="error" v-if="!$v.salePrice.required">* {{$t('common.salepricerequired')}}</span>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <label for="manufacturerbrand">{{$t('common.quantity')}}</label>
                          <input  v-model.trim="$v.inventory.$model" type="number" class="form-control" />
                          <span class="error" v-if="!$v.inventory.required">* {{$t('common.quantityrequired')}}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row row-box">
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="control-label">{{$t('common.category')}}</label>
                        
                          <table>
                              <tr>
                                <td>
                                  <select class="form-control" @change="getSecond(firstCategoryID)" style="width: 150px" v-model="firstCategoryID">
                                      <option v-for="(item, index) in firstCategory" :key="index" :value="item._id">{{item.categoryName}}</option>
                                  </select>
                                </td>
                                <td>
                                  <select class="form-control" @change="getThird(secondCategoryID)" style="width: 150px" v-model="secondCategoryID">
                                      <option v-for="(item, index) in secondCategory" :key="index" :value="item._id">{{item.categoryName}}</option>
                                  </select>
                                </td>
                                <td>
                                  <select class="form-control" @change="getCategory(thirdCategoryID)" style="width: 150px" v-model="thirdCategoryID">
                                      <option v-for="(item, index) in thirdCategory" :key="index" :value="item._id">{{item.categoryName}}</option>
                                  </select>
                                </td>
                              </tr>
                            </table>
                          
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="control-label">{{$t('common.brand')}}</label>

                           <select class="form-control" v-model.trim="$v.brandId.$model">
                                <option value="">{{$t('content.products.selectbrand')}}</option>
                                <option v-for="(item,index) in brandData" :key="index" :value="item._id">{{item.brandName}}</option>
                            </select>
                             <span class="error" v-if="!$v.brandId.required">* {{$t('common.brandrequired')}}</span>

                        </div>
                      </div>
                    </div>

                    <div class="row">
                      
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="price">{{$t('content.products.placeOfShipment')}}</label>
                          <input id="price" name="place" type="text" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="manufacturername">{{$t('content.products.mininumNumberOfOrderedPieces')}}</label>
                          <input id="sale_price" name="mininum" type="number" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label for="manufacturerbrand">{{$t('content.products.volume')}}</label>
                          <input  type="number" class="form-control" />
                        </div>
                      </div>
                      <div class="col-lg-43">
                        <div class="form-group">
                          <label for="manufacturerbrand">{{$t('content.products.weight')}}</label>
                          <input type="number" class="form-control" />
                        </div>
                      </div>
                    </div>

                    <div class="form-group" style="margin-top: 20px">
                      <label for="productdesc">{{$t('content.products.description')}}</label>
                      <ckeditor v-model="editorData"  :editor="editor" :config="editorConfig"></ckeditor>
                    </div>
                  </form>
                </div>

      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="editProductModel = false">{{$t('common.close')}}</b-button>
        <b-button variant="primary" @click="submitData">
          {{$t('common.submit')}}
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>


  </Layout>
</template>